import { useState } from 'react'
import styles from './styles.module.css'

const TagsInput = ({ values, onChange, type = '', placeholder = '' }) => {
  function handleKeyDown(e) {
    const value = e.target.value
    if (e.key === 'Enter') {
      if (!value.trim()) return
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
      if (type === 'email' && !regex.test(value)) return
      onChange([...values, value])
      e.target.value = ''
    }
    if (e.key === 'Backspace' && !value) {
      if (values.length < 2) return
      values.pop()
      onChange([...values])
    }
  }

  function removeTag(index) {
    onChange(values.filter((el, i) => i !== index))
  }

  return (
    <div className={styles['tags-input-container']}>
      {values.map((tag, index) => (
        <div className={styles['tag-item']} key={index}>
          <span className="text">{tag}</span>
          <span className={styles.close} onClick={() => removeTag(index)}>
            &times;
          </span>
        </div>
      ))}
      <input
        onKeyDown={handleKeyDown}
        type="text"
        className={styles['tags-input']}
        placeholder={placeholder}
      />
    </div>
  )
}

export default TagsInput
